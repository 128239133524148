@font-face {
  font-family: Conthrax;
  src: url("conthrax-rg.f7d62baf.otf") format("opentype");
  font-style: normal;
  font-weight: 100 400;
}

@font-face {
  font-family: Conthrax-Bold;
  src: url("conthrax-hv.383c8f9f.otf") format("opentype");
  font-style: normal;
  font-weight: 500 900;
}

@font-face {
  font-family: Inter;
  src: url("Inter-VariableFont_slnt,wght.1faf0d9b.ttf") format("truetype");
}

:root {
  --font-sans: Inter, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
}

html {
  background-color: #0000;
}

body {
  font-family: var(--font-sans);
  max-block-size: 100%;
  max-inline-size: 100%;
  overflow: hidden;
}

/*# sourceMappingURL=index.621112ef.css.map */
